<script setup>
import { useOnlineUsers } from '@/composables/useOnlineUsers';

const {
  incomingCall,
  incomingCaller,
  acceptCall,
  declineCall
} = useOnlineUsers();
</script>

<template>
<div class="container">
  <div class="row my-5 incomming-call" v-if="incomingCall">
    <div class="caller-details">
      <img
        src="https://luvomedical.com/wp-content/uploads/2020/05/generic-profile-picture.jpg"
        alt=""
        srcset=""
        width="50px"
      />
      <span>
        Incoming Call From <strong>{{ incomingCaller }}</strong>
      </span>
    </div>
    <div class="btn-container" role="group">
      <button type="button" class="btn btn-accept" @click="acceptCall">
        <i class="fa fa-phone"></i>
      </button>
      <button type="button" class="btn btn-decline" @click="declineCall">
        <i class="fas fa-phone-slash"></i>
      </button>
    </div>
  </div>
</div>
</template>

<style scoped>
.incomming-call {
    position: fixed;
    top: 1%;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    height: auto;
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: slide-in 0.5s forwards;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
}

.btn-accept {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: #00ff00;
}

.btn-accept i {
    font-size: 1.5rem;
    color: white;
    transform: rotateY(180deg);
}

.btn-accept:hover {
    background-color: #138b59;
}

.btn-decline {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: #ff0000;
}

.btn-decline i {
    font-size: 1.5rem;
    color: white;
}

.btn-decline:hover {
    background-color: #7c2929;
}

.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.incomming-call span {
    font-size: 20px;
    font-weight: 500;
    color: #000;
}

.caller-details {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.caller-details img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

@keyframes slide-in {
    0% {
        transform: translateX(-0%);
    }

    100% {
        transform: translateX(-50%);
    }
}
</style>