import { defineStore } from 'pinia';
import api from '@/utils/api';

export const useMessageStore = defineStore('message', {
    state: () => ({
        message: '',
        unreadNotificationsCount: 0,
        hasNotChangedPassword: false
            // userType: ''
    }),
    getters: {
        // Getter for unread notifications count
        unreadCount: (state) => state.unreadNotificationsCount,

        // Getter for hasNotChangedPassword
        isPasswordNotUpdated: (state) => state.hasNotChangedPassword,
    },
    actions: {
        setMessage(msg) {
            this.message = msg;
        },
        clearMessage() {
            this.message = '';
        },
        setUserType(userType) {
            this.userType = userType;
        },
        clearUserType() {
            this.userType = '';
        },
        // async fetchUserType() {
        //     const response = await api.get('/get-profile', {
        //         headers: {
        //             Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
        //         }
        //     });
        //     return response.data.result.result.user_type;
        // },
        async fetchUser() {
            try {
                const response = await api.get('/get-profile', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
                    }
                });

                console.log(response.data.result.result);
                return response.data.result.result;
                // this.userType = response.data.result.result.user_type;
            } catch (error) {
                console.error(error);
            }
        },
        setUnreadNotificationsCount(count) {
            // console.log("count: " + count);
            this.unreadNotificationsCount = count;
        },
        getUnreadNotificationsCount() {
            return this.unreadNotificationsCount;
        },
        // Updates the hasNotChangedPassword flag based on sessionStorage
        setPasswordUpdatedFlag() {
            const sessionPassword = sessionStorage.getItem('has_changed_password');
            this.hasNotChangedPassword = sessionPassword === '0';
        },
        // Clears the password updated flag
        clearPasswordUpdatedFlag() {
            this.hasNotChangedPassword = false;
            sessionStorage.setItem('has_changed_password', '1');
        },
    }
});