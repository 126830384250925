// src/main.js
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import echo from "./services/pusher";
import { createPinia } from "pinia";
import { PrimeVue } from "@primevue/core";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import api from "./utils/api";
import vue3GoogleLogin from 'vue3-google-login'
import { definePreset } from '@primevue/themes';

import Tooltip from 'primevue/tooltip';

import Aura from "@primevue/themes/aura";
import "@/assets/styles.scss";
import "@/assets/tailwind.css";
// import VCalendar from 'v-calendar';
import { setupCalendar, Calendar, DatePicker } from "v-calendar";
import "v-calendar/style.css";
import VueKhalti from 'vue-khalti'

const app = createApp(App);
app.use(createPinia());

app.directive('tooltip', Tooltip);

import { useMessageStore } from "./stores/messageStore";
const messageStore = useMessageStore();

messageStore.setPasswordUpdatedFlag();

// const authUserType = messageStore.fetchUser();

// Make Echo available globally
app.config.globalProperties.$echo = echo;



const MyPreset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{orange.50}',
            100: '{orange.100}',
            200: '{orange.200}',
            300: '{orange.300}',
            400: '{orange.400}',
            500: '{orange.500}',
            600: '{orange.600}',
            700: '{orange.700}',
            800: '{orange.800}',
            900: '{orange.900}',
            950: '{orange.950}'
        },
        colorScheme: {
            light: {
                primary: {
                    color: '{orange.600}',
                    inverseColor: '#ffffff',
                    hoverColor: '{orange.700}',
                    activeColor: '{orange.700}'
                },
                highlight: {
                    background: '{orange.600}',
                    focusBackground: '{orange.700}',
                    color: '#ffffff',
                    focusColor: '#ffffff'
                }
            },
        }
    }
});


app.use(router);
app.use(PrimeVue, {
    theme: {
        preset: MyPreset,
        options: {
            darkModeSelector: false || 'none',
        },
    },
});
const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
app.use(vue3GoogleLogin, {
    clientId: clientId,
})

app.use(ToastService);
app.use(ConfirmationService);
// app.use(VCalendar, {});
app.use(setupCalendar, {});
app.component("VCalendar", Calendar);
app.component("VDatePicker", DatePicker);
app.config.globalProperties.$appUrl = process.env.VUE_APP_MAIN_URL;

const authUser = sessionStorage.getItem('apiToken');


import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import np from './locales/np.json';

// Create I18n instance
const i18n = createI18n({
    locale: 'en', // default locale
    fallbackLocale: 'en', // fallback locale
    messages: {
        en: en,
        np: np
    }
});
app.use(i18n);


export async function getRecent3NotificationsAndCount() {
    try {
        const response = await api.get('/get-recent-3-notifications', {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
                }
            })
            // console.log(response);

        messageStore.setUnreadNotificationsCount(response.data.notifications_count);
    } catch (error) {
        console.error(error);
    }
}

if (authUser) {
    try {
        const response = await api.get('/get-profile', {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
            }
        });
        const data = response.data.result.result;
        const userType = data.user_type;
        const userId = data.id;
        const guruId = data.guru_id ? data.guru_id : null;
        app.config.globalProperties.$userType = data.user_type;
        app.config.globalProperties.$userId = data.id;
        app.config.globalProperties.$guruId = data.guru_id ? data.guru_id : null;
        sessionStorage.setItem('has_changed_password', data.has_changed_password);

        echo.connector.pusher.connection.bind("connected", function() {
            // console.log("connected");
        });

        echo.connector.pusher.connection.bind("disconnected", function() {
            // console.log("disconnected");
        });


        if (guruId) {
            // console.log(`appointment.${guruId}`);
            echo.private(`appointment.${guruId}`)
                .subscribed(() => {
                    // console.log('Subscribed to appointment');
                })
                .listen('AppointmentBooked', (e) => {
                    // console.log('appointment booked listened');
                    // console.log(e.message);
                    getRecent3NotificationsAndCount();
                });
        }

        if (userId) {
            echo.private(`refund-request-response.${userId}`)
                .subscribed(() => {
                    // console.log('Subscribed to refund request response');
                })
                .listen('RefundRequestResponse', (e) => {
                    // console.log('refund request response listened');
                    // console.log(e.message);
                    getRecent3NotificationsAndCount();
                });
        }

        if (userType === 'admin') {
            console.log(userType);
            echo.private('refund-request-for-admin')
                .subscribed(() => {
                    // console.log('Subscribed to refund-request-for-admin');
                })
                .listen('RefundRequested', (e) => {
                    // console.log('refund requested listened');
                    // console.log(e);
                    getRecent3NotificationsAndCount();
                })
                // console.log(userType);
        }
        getRecent3NotificationsAndCount();
    } catch (error) {
        console.error(error);
    }
}

app.use(VueKhalti);
app.mount("#app");