<script setup>
import { ref, defineProps, onMounted } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import api from "@/utils/api"; // Ensure the path is correct
import { useI18n } from 'vue-i18n';
const { t:ti } = useI18n();
const visible = ref(false);
const rating = ref(0); // Holds the rating value
const review = ref(''); // Holds the review text
const rated = ref(false);

const props = defineProps({
  guruId: Number, // Guru ID from parent component
});

const setRating = (value) => {
  rating.value = value;
};

const rateGuru = async () => {
  try {
    const postData = {
      guru_id: props.guruId,
      rating: rating.value, // User's rating
      review: review.value, // User's review
    };

    const response = await api.post("guru/ratings", postData, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("apiToken")}`,
      },
    });
    getClientToGuruRating();
    visible.value = false;
    rating.value = 0;
    review.value = '';
  } catch (error) {
    console.error("Failed to submit rating:", error);
  }
};

onMounted(() => {
  if(props.guruId){
    getClientToGuruRating();
  }
});

const getClientToGuruRating = async () => {
  try {
    // const response = await api.get(`client-to-guru-reviews/${props.guruId}`, {
    //   headers: {
    //     Authorization: `Bearer ${sessionStorage.getItem("apiToken")}`,
    //   },
    // });
    // if(response.data.success){
    //   rating.value = response.data.result.data.rating;
    //   review.value = response.data.result.data.review;
    //   rated.value = true;
    // }
  } catch (error) {
    console.error("Failed to fetch rating:", error);
  }
};
</script>

<template>
  <div>
    <Button :label="ti('my_bookings_page.rated')" icon="pi pi-star" @click="visible = true" v-if="rated" class="p-button-sm rounded-3xl flex-auto md:flex-initial whitespace-nowrap" severity="secondary"/>
    <Button :label="ti('my_bookings_page.rate_guru')" icon="pi pi-star" @click="visible = true" v-else class="p-button-sm flex-auto rounded-3xl md:flex-initial whitespace-nowrap" severity="secondary"/>
    <Dialog v-model:visible="visible" modal :header="ti('my_bookings_page.rate_guru')" :style="{ width: '25rem' }">
      <div class="flex justify-center mb-4">
        <div class="stars">
          <span
            v-for="star in 5"
            :key="star"
            class="star"
            :class="{ active: star <= rating }"
            @click="setRating(star)"
          >★</span>
        </div>
      </div>
      <div class="mb-4">
        <Textarea v-model="review" id="review" class="w-full" placeholder="Write Your Expirence"/>
      </div>
      <div class="flex justify-end gap-2">
        <Button type="button" label="Cancel" severity="secondary" @click="visible = false" />
        <Button type="button" label="Rate" @click="rateGuru" />
      </div>
    </Dialog>
  </div>
</template>

<style scoped>
.stars {
  display: flex;
}

.star {
  font-size: 3rem;
  color: gray;
  cursor: pointer;
  margin: 0 5px;
  user-select: none;
}

.star.active {
  color: #c2410c;
}

.star:hover {
  color: #c2410c;
}
</style>
