<!-- src/components/TopLoader.vue -->
<script setup>
import { ref, watch } from 'vue'

const isLoading = ref(false)
const progress = ref(0)
let timer = null

const startLoader = () => {
  isLoading.value = true
  progress.value = 0
  incrementProgress()
}

const incrementProgress = () => {
  timer = setInterval(() => {
    if (progress.value < 90) {
      progress.value += Math.random() * 5
    }
  }, 100)
}

const finishLoader = () => {
  clearInterval(timer)
  progress.value = 100
  setTimeout(() => {
    isLoading.value = false
  }, 500) // Delay to show the full progress bar before hiding
}

// Listen to custom events for starting and finishing the loader
window.addEventListener('start-loader', startLoader)
window.addEventListener('finish-loader', finishLoader)

watch(isLoading, (newValue) => {
  if (!newValue) clearInterval(timer) // Stop incrementing progress when loading ends
})
</script>

<template>
    <div class="top-loader" :style="{ width: `${progress}%` }" v-show="isLoading"></div>
  </template>
  
 
  
  <style scoped>
  .top-loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 3px;
    background-color: #ff0000; /* Change to your preferred color */
    z-index: 9999;
    transition: width 0.4s ease;
  }
  </style>
  