<script setup>
import { useLayout } from '@/layout/composables/layout';
import { onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { useI18n } from 'vue-i18n';
const { t: ti } = useI18n();

const route = useRoute();

const { layoutState, setActiveMenuItem, onMenuToggle } = useLayout();

const props = defineProps({
    item: {
        type: Object,
        default: () => ({})
    },
    index: {
        type: Number,
        default: 0
    },
    root: {
        type: Boolean,
        default: true
    },
    parentItemKey: {
        type: String,
        default: null
    }
});

const isActiveMenu = ref(false);
const itemKey = ref(null);

onBeforeMount(() => {
    itemKey.value = props.parentItemKey ? props.parentItemKey + '-' + props.index : String(props.index);

    const activeItem = layoutState.activeMenuItem;

    isActiveMenu.value = activeItem === itemKey.value || activeItem ? activeItem.startsWith(itemKey.value + '-') : false;
});

watch(
    () => layoutState.activeMenuItem,
    (newVal) => {
        isActiveMenu.value = newVal === itemKey.value || newVal.startsWith(itemKey.value + '-');
    }
);

const isLoading = ref(false);
function itemClick(event, item) {
    // isLoading.value = true;
    
    if (item.disabled) {
        event.preventDefault();
        return;
    }

    if ((item.to || item.url) && (layoutState.staticMenuMobileActive || layoutState.overlayMenuActive)) {
        onMenuToggle();
    }

    if (item.command) {
        item.command({ originalEvent: event, item: item });
    }

    const foundItemKey = item.items ? (isActiveMenu.value ? props.parentItemKey : itemKey) : itemKey.value;

    setActiveMenuItem(foundItemKey);

    // isLoading.value = false;
}

function checkActiveRoute(item) {
    return route.path === item.to;
}

// const translatedLabel = computed(() => ti(props.item.label));

</script>

<template>
    <li :class="{ 'layout-root-menuitem': root, 'active-menuitem': isActiveMenu }">
        <div v-if="root && item.visible !== false" class="layout-menuitem-root-text">{{ ti(item.label) }}</div>
        <a v-if="(!item.to || item.items) && item.visible !== false" :href="item.url" @click="itemClick($event, item, index)" :class="item.class" :target="item.target" tabindex="0">
            <template v-if="item.image">
                <img :src="item.image" alt="" class="layout-menuitem-icon w-6 h-6" />
            </template>
            <i v-if="!item.image" :class="item.icon" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text">{{ ti(item.label) }}</span>
            <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
        </a>
        <router-link v-if="item.to && !item.items && item.visible !== false" @click="itemClick($event, item, index)" :class="[item.class, { 'active-route': checkActiveRoute(item) }]" tabindex="0" :to="item.to">
            <template v-if="item.image">
                <img :src="item.image" alt="" class="layout-menuitem-icon w-6 h-6" />
            </template>
            <i v-if="!item.image" :class="item.icon" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text">{{ ti(item.label) }}</span>
            <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
        </router-link>
        <!-- <ul class="layout-menu" v-if="isLoading">
            <li class="menu-item">
                <div class="menu-item-content">
                    <div class="menu-icon">
                        <i class="pi pi-spin pi-spinner"></i>
                    </div>
                    <div class="menu-text">
                        <span class="menu-text"></span>
                    </div>
                </div>
            </li>
        </ul> -->
        <Transition v-if="item.items && item.visible !== false" name="layout-submenu">
            <ul v-show="root ? true : isActiveMenu" class="layout-submenu">
                <app-menu-item v-for="(child, i) in item.items" :key="child" :index="i" :item="child" :parentItemKey="itemKey" :root="false"></app-menu-item>
            </ul>
        </Transition>
    </li>
</template>

<style lang="scss" scoped>
    .menu-item-content {
        display: flex;
        align-items: center;
        padding: 1rem;
    }

    .menu-icon {
        margin-right: 1rem;
    }

    .menu-text {
        display: inline-block;
        width: 100px;
        height: 1rem;
        background-color: #f0f0f0;
    }
</style>
