<script setup>
import api from "@/utils/api";
import { onMounted, ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';

// import { useI18n } from 'vue-i18n';
// const { t: ti, locale } = useI18n();

const isLoading = ref(true);
// Holds the full menu model
const fullModel = ref([
    {
        label: 'sidebar.general',
        userType: 'admin',
        items: [
            { label: 'sidebar.dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'sidebar.gurus', icon: 'pi pi-fw pi-user', to: '/admin/gurus' },
            { label: 'sidebar.users', icon: 'pi pi-fw pi-user', to: '/admin/users' },
            { label: 'sidebar.refund-request', icon: 'pi pi-fw pi-wallet', to: '/admin/refund-request' },
            { label: 'sidebar.horoscope', icon: 'pi pi-fw pi-moon', to: '/admin/horoscope' },
            { label: 'sidebar.reviews', icon: 'pi pi-thumbs-up', to: '/admin/reviews' },
            { label: 'sidebar.bookings', icon: 'pi pi-book', to: '/admin/bookings' },
            { label: 'sidebar.payments', icon: 'pi pi-money-bill', to: '/admin/payments' },
            { label: 'sidebar.blogs', icon: 'pi pi-pencil', to: '/blogs' },
        ]
    },

    {
        label: 'sidebar.general',
        userType: 'guru', 
        items: [
            { label: 'sidebar.dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'sidebar.slots', icon: 'pi pi-fw pi-calendar', to: '/guru/slots' },
            { label: 'sidebar.client-bookings', icon: 'pi pi-fw pi-user', to: '/guru/clients' },
        ]
    },

    {
        label: 'sidebar.general',
        userType: 'client', 
        items: [
            { label: 'sidebar.dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            // { label: 'sidebar.browse-gurus', icon: 'pi pi-fw pi-user', to: '/gurus' },
            { label: 'sidebar.browse-gurus', image: require('@/assets/images/guru.png'), to: '/gurus' },
            { label: 'sidebar.quick-bookings', icon: 'pi pi-fw pi-calendar-clock', to: '/quick-bookings' },
            { label: 'sidebar.my-bookings', icon: 'pi pi-fw pi-calendar', to: '/my-bookings' },
            { label: 'sidebar.my-payments', icon: 'pi pi-fw pi-indian-rupee', to: '/my-payments' },
            { label: 'sidebar.horoscope', icon: 'pi pi-moon', to: '/horoscope' },
        ]
    },

    // {
    //     label: 'sidebar.profile-and-security',
    //     userType: 'all', 
    //     items: [
    //         { label: 'sidebar.profile', icon: 'pi pi-fw pi-user', to: '/profile' },
    //         { label: 'sidebar.password', icon: 'pi pi-fw pi-cog', to: '/password' },
    //     ]
    // },

    {
        label: 'sidebar.profile-and-security',
        userType: 'all',
        items: [
            {
                label: 'sidebar.settings',
                icon: 'pi pi-fw pi-user',
                items: [
                    { 
                        label: 'sidebar.profile', 
                        icon: 'pi pi-fw pi-user', 
                        to: '/profile' 
                    },
                    { 
                        label: 'sidebar.password', 
                        icon: 'pi pi-fw pi-cog', 
                        to: '/password' 
                    },
                ],
            },
        ],
    },

]);

const model = ref([]);

onMounted(async () => {
    try {
        if (!sessionStorage.getItem('apiToken')) {
            return;
        }
        const response = await api.get('/get-profile', {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
            }
        });
        const userType = response.data.result.result.user_type;
        isLoading.value = false;
        model.value = fullModel.value.filter(
            section => section.userType === userType || section.userType === 'all'
        );
    } catch (error) {
        console.error('Failed to fetch user profile:', error);
    }
});
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item.label">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>

    <ul class="layout-menu" v-if="isLoading">
        <li class="menu-item">
            <div class="menu-item-content" v-for="n in 6" :key="n">
                <div class="menu-icon">
                    <i class="pi pi-spin pi-spinner"></i>
                </div>
                <div class="menu-text">
                    <span class="menu-text"></span>
                </div>
            </div>
        </li>
    </ul>
    
</template>

<style scoped>
    .menu-item-content {
        display: flex;
        align-items: center;
        padding: 1rem;
    }

    .menu-icon {
        margin-right: 1rem;
    }

    .menu-text {
        display: inline-block;
        width: 100px;
        height: 1rem;
        background-color: #f0f0f0;
    }

</style>
