<script setup>
import { useOnlineUsers } from '@/composables/useOnlineUsers';
import { ref, watch, onUnmounted, computed } from 'vue';
import { errorMessages } from 'vue/compiler-sfc';

const {
    QuickBookingRequest,   
    QuickBookingRequestUserName,
    QuickBookingRequestUserId,
    acceptQuickBookingRequest,
    declineQuickBookingRequest,
    errorMessage,
    errorOnAccept,
} = useOnlineUsers();

const countdown = ref(60);
const countdownTimer = ref(null);

const startCountdown = () => {
    if (countdownTimer.value) {
        clearInterval(countdownTimer.value);
    }

    countdown.value = 60;

    countdownTimer.value = setInterval(() => {
        countdown.value--;

        if (countdown.value <= 0) {
            clearInterval(countdownTimer.value);
            declineQuickBookingRequest();
        }
    }, 1000);
};

watch(() => QuickBookingRequest.value, (newValue) => {
    if (newValue) {
        startCountdown();
    }
});

onUnmounted(() => {
    if (countdownTimer.value) {
        clearInterval(countdownTimer.value);
    }
});

const handleAcceptRequest = () => {
    if (countdownTimer.value) {
        clearInterval(countdownTimer.value);
    }
    acceptQuickBookingRequest();
};

const handleDeclineRequest = () => {
    if (countdownTimer.value) {
        clearInterval(countdownTimer.value);
    }
    declineQuickBookingRequest();
};

const dismissError = () => {
    errorOnAccept.value = false;
    errorMessage.value = '';
};

const formattedCountdown = computed(() => {
    return countdown.value < 10 ? `0${countdown.value}` : `${countdown.value}`;
});
</script>

<template>
<div class="quick-booking-container" v-if="QuickBookingRequest">
    <div class="quick-booking-card">
        <div class="countdown-badge">
            {{ formattedCountdown }}
        </div>
        
        <div class="user-info">
            <div class="user-avatar">
                <img 
                    src="https://luvomedical.com/wp-content/uploads/2020/05/generic-profile-picture.jpg" 
                    alt="User Profile"
                />
            </div>
            
            <div class="user-details">
                <h3>Incoming Quick Call Request</h3>
                <p>From <strong>{{ QuickBookingRequestUserName }}</strong></p>
            </div>
        </div>
        
        <div class="action-buttons">
            <button 
                class="btn-accept" 
                @click="handleAcceptRequest"
            >
                Accept
            </button>
            <button 
                class="btn-decline" 
                @click="handleDeclineRequest"
            >
                Decline
            </button>
        </div>
    </div>
</div>

<div v-if="errorOnAccept">
    <div class="quick-booking-container">
    <div class="quick-booking-card">
       
        <p class="text-gray-500 text-center">
        {{ errorMessage }}
        </p>
        
        <div class="action-buttons">
            <button 
                class="btn-accept" 
                @click="dismissError"
            >
                Dismiss
            </button>
           
        </div>
    </div>
</div>
</div>

</template>

<style scoped>
.quick-booking-container {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    width: 100%;
    max-width: 500px;
    padding: 0 15px;
}

.quick-booking-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: 15px;
    border: 2px solid #f0f0f0;
}

.countdown-badge {
    position: absolute;
    top: -15px;
    right: -15px;
    background-color: #ff4444;
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.user-info {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.user-avatar img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #e0e0e0;
}

.user-details h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.user-details p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #666;
}

.action-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 100%;
}

.btn-accept, .btn-decline {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;
}

.btn-accept {
    background-color: #4CAF50;
    color: white;
}

.btn-accept:hover {
    background-color: #45a049;
}

.btn-decline {
    background-color: #f44336;
    color: white;
}

.btn-decline:hover {
    background-color: #d32f2f;
}

@media (max-width: 600px) {
    .quick-booking-container {
        max-width: 95%;
    }

    .user-info {
        flex-direction: column;
        text-align: center;
    }

    .action-buttons {
        flex-direction: column;
    }

    .btn-accept, .btn-decline {
        width: 100%;
    }
}
</style>