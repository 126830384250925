import AppLayout from '@/layout/AppLayout.vue';
import api from "@/utils/api";

import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [{
            path: '/dashboard',
            component: AppLayout,
            children: [{
                    path: '/dashboard',
                    name: 'dashboard',
                    component: () =>
                        import ('@/views/Dashboard.vue'),
                    meta: { requiresAuth: true, title: 'Dashboard' }
                },
                {
                    path: '/caller-list',
                    name: 'caller-list',
                    component: () =>
                        import ('@/views/pages/call/CallerList.vue'),
                    meta: { requiresAuth: true, title: 'Caller List' }
                },
                // admin routes
                {
                    path: '/admin/gurus',
                    name: 'guru.index',
                    component: () =>
                        import ('@/views/pages/admin/guru/GurusList.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Admin - Gurus' }
                },
                {
                    path: '/admin/gurus/:id/details',
                    name: 'guru.details',
                    component: () =>
                        import ('@/views/pages/admin/guru/GurusDetails.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Admin - Gurus' }
                },
                {
                    path: '/admin/users',
                    name: 'user.index',
                    component: () =>
                        import ('@/views/pages/admin/users/UsersList.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Admin - Users' }
                },
                {
                    path: '/admin/users/:id/details',
                    name: 'user.details',
                    component: () =>
                        import ('@/views/pages/admin/guru/UsersDetails.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Admin - Users' }
                },
                {
                    path: '/admin/reviews',
                    name: 'review.index',
                    component: () =>
                        import ('@/views/pages/admin/guru/ReviewsList.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Admin - Reviews' }
                },
                {
                    path: '/admin/bookings',
                    name: 'admin.bookings',
                    component: () =>
                        import ('@/views/pages/admin/guru/BookingsList.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Admin - Bookings' }
                },
                {
                    path: '/admin/booking/details/:id',
                    name: 'admin.bookings.details',
                    component: () =>
                        import ('@/views/pages/admin/guru/BookingDetails.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Admin - Booking Details' }
                },
                // guru routes
                {
                    path: '/guru/slots',
                    name: 'slot.index',
                    component: () =>
                        import ('@/views/pages/guru/slots/SlotsPage.vue'),
                    meta: { requiresAuth: true, userType: 'guru', title: 'Guru - Slots' }
                },
                {
                    path: '/guru/clients',
                    name: 'client.index',
                    component: () =>
                        import ('@/views/pages/call/CallerList.vue'),
                    meta: { requiresAuth: true, userType: 'guru', title: 'Guru - Clients' }
                },
                {
                    path: '/call',
                    name: 'call',
                    component: () =>
                        import ('@/views/pages/Call.vue'),
                    meta: { requiresAuth: true, title: 'Call' }
                },
                // client routes
                {
                    path: '/gurus',
                    name: 'gurus.index',
                    component: () =>
                        import ('@/views/pages/client/gurus/GuruList.vue'),
                    meta: { requiresAuth: true, userType: 'client', title: 'Gurus List' }
                },
                {
                    path: '/guru/:id',
                    name: 'gurus.show',
                    component: () =>
                        import ('@/views/pages/client/gurus/GuruDetail.vue'),
                    meta: { requiresAuth: true, userType: 'client', title: 'Guru Details' }
                },
                {
                    path: '/my-bookings',
                    name: 'bookings.index',
                    component: () =>
                        import ('@/views/pages/call/CallerList.vue'),
                    meta: { requiresAuth: true, userType: 'client', title: 'My Bookings' }
                },
                {
                    path: '/my-payments',
                    name: 'payments.index',
                    component: () =>
                        import ('@/views/pages/PaymentsList.vue'),
                    meta: { requiresAuth: true, userType: 'client', title: 'My Payments' }
                },
                {
                    path: '/quick-bookings',
                    name: 'quick-bookings',
                    component: () =>
                        import ('@/views/pages/client/QuickBooking.vue'),
                    meta: { requiresAuth: true, userType: 'client', title: 'Quick Bookings' }
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: () =>
                        import ('@/views/pages/auth/ProfilePage.vue'),
                    meta: { requiresAuth: true, title: 'Profile' }
                },
                {
                    path: '/password',
                    name: 'password',
                    component: () =>
                        import ('@/views/pages/auth/PasswordPage.vue'),
                    meta: { requiresAuth: true, title: 'Change Password' }
                },
                {
                    path: '/notifications',
                    name: 'notifications',
                    component: () =>
                        import ('@/views/pages/NotificationView.vue'),
                    meta: { title: 'Notifications' }
                },
                {
                    path: '/admin/payments',
                    name: 'payments',
                    component: () =>
                        import ('@/views/pages/admin/PaymentPage.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Payments' }
                },
                {
                    path: '/admin/refund-request',
                    name: 'refund-request',
                    component: () =>
                        import ('@/views/pages/RefundRequest.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Refund Request' }
                },
                {
                    path: '/guru/:id/reschedule/:slotBookingIdForReschedule',
                    name: 'gurus.reschedule',
                    component: () =>
                        import ('@/views/pages/client/gurus/Reschedule.vue'),
                    meta: { requiresAuth: true, userType: 'client', title: 'Reschedule Appointment' },
                    beforeEnter: (to, from, next) => {
                        if (to.query.cancel_and_reschedule) {
                            next(); // Proceed to the component
                        } else {
                            next({ name: 'some.error.route' }); // Replace with your error route
                        }
                    }
                },
                {
                    path: '/admin/horoscope',
                    name: 'admin.horoscope',
                    component: () =>
                        import ('@/views/pages/admin/ZodiacHoroscope.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Admin Horoscope' }
                },
                {
                    path: '/horoscope',
                    name: 'horoscope',
                    component: () =>
                        import ('@/views/pages/Horoscope.vue'),
                    meta: { requiresAuth: true, userType: 'client', title: 'Horoscope' }
                },
                {
                    path: '/blogs',
                    name: 'blog.index',
                    component: () =>
                        import ('@/views/pages/admin/Blogs.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Admin - Blogs' }
                },
                {
                    path: '/blogs/create',
                    name: 'blog.create',
                    component: () =>
                        import ('@/views/pages/admin/CreateBlog.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Admin - Create - Blogs' }
                },
                {
                    path: '/blogs/:slug/edit',
                    name: 'blog.edit',
                    component: () =>
                        import ('@/views/pages/admin/EditBlog.vue'),
                    meta: { requiresAuth: true, userType: 'admin', title: 'Admin - Edit - Blogs' }
                },
            ]
        },
        {
            path: '/',
            name: 'home',
            component: () =>
                import ('@/views/pages/HomePage.vue'),
            meta: { title: 'Home' }
        },
        {
            path: '/book-appointment',
            name: 'book-appointment',
            component: () =>
                import ('@/views/pages/BookAppointment.vue'),
            meta: { title: 'Book Appointment' }
        },
        // {
        //     path: '/guru/book-appointment',
        //     name: 'guru-book-appointment',
        //     component: () =>
        //         import ('@/views/pages/GuruPage.vue'),
        //     meta: { title: 'Guru Detail' }
        // },
        {
            path: '/guru/:id/book-appointment',
            name: 'book-appointment',
            component: () =>
                import ('@/views/pages/GuruPage.vue'),
            meta: { title: 'Guru Detail' }
        },
        {
            path: '/astrologers',
            name: 'astrologers',
            component: () =>
                import ('@/views/pages/GuruListPage.vue'),
            meta: { title: 'Guru Detail' }
        },
        {
            path: '/shop',
            name: 'shop',
            component: () =>
                import ('@/views/pages/Shop.vue'),
            meta: { title: 'Shop' }
        },
        {
            path: '/rasifal',
            name: 'rasifal',
            component: () =>
                import ('@/views/pages/Rasifal.vue'),
            meta: { title: 'Rasifal' }
        },
        {
            path: '/live-astrologers',
            name: 'live-astrologers',
            component: () =>
                import ('@/views/pages/LiveAstrologer.vue'),
            meta: { title: 'Live Astrologers' }
        },
        // {
        //     path: '/articles',
        //     name: 'articles',
        //     component: () =>
        //         import ('@/views/pages/Articles.vue'),
        //     meta: { title: 'Articles' }
        // },
        {
            path: '/articles/:slug',
            name: 'articles',
            component: () =>
                import ('@/views/pages/Article.vue'),
            meta: { title: 'Article' }
        },
        // {
        //     path: '/blogs',
        //     name: 'blogs',
        //     component: () =>
        //         import ('@/views/pages/Blog.vue'),
        //     meta: { title: 'Blogs' }
        // },
        {
            path: '/call-appointment',
            name: 'call-appointment',
            component: () =>
                import ('@/views/pages/call/CallContainer.vue'),
            meta: { title: 'Call Appointment' }
        },
        {
            path: '/login',
            name: 'login',
            component: () =>
                import ('@/views/pages/auth/LoginPage.vue'),
            meta: { title: 'Login' }
        },
        {
            path: '/register',
            name: 'register',
            component: () =>
                import ('@/views/pages/auth/RegisterPage.vue'),
            meta: { title: 'Register' }
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () =>
                import ('@/views/pages/auth/ForgotPasswordPage.vue'),
            meta: { title: 'Forgot Password' }
        },
        {
            path: '/enter-otp',
            name: 'enter-otp',
            component: () =>
                import ('@/views/pages/auth/EnterOtpPage.vue'),
            meta: { title: 'Enter OTP' }
        },
        {
            path: '/register-otp',
            name: 'register-otp',
            component: () =>
                import ('@/views/pages/auth/RegisterOtp.vue'),
            meta: { title: 'Register OTP' }
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () =>
                import ('@/views/pages/auth/ResetPasswordPage.vue'),
            meta: { title: 'Reset Password' }
        },
        {
            path: '/pages/notfound',
            name: 'notfound',
            component: () =>
                import ('@/views/pages/NotFound.vue'),
            meta: { title: 'Page Not Found' }
        },
        {
            path: '/access',
            name: 'accessDenied',
            component: () =>
                import ('@/views/pages/auth/AccessPage.vue'),
            meta: { title: 'Access Denied' }
        },
        {
            path: '/error',
            name: 'error',
            component: () =>
                import ('@/views/pages/auth/ErrorPage.vue'),
            meta: { title: 'Error' }
        },
        {
            path: '/class',
            name: 'class',
            component: () =>
                import ('@/views/pages/Class.vue'),
            meta: { title: 'Class' }
        },
        {
            path: '/call',
            name: 'call',
            component: () =>
                import ('@/views/pages/Call.vue'),
            meta: { title: 'Call' }
        },
    ]
});

router.beforeEach(async(to, from, next) => {
    const isAuthenticated = !!sessionStorage.getItem('apiToken');
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated) {
            next({ name: 'login' });
        } else {
            try {
                const response = await api.get('/get-profile', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
                    }
                });

                const data = response.data.result.result;
                const userType = data.user_type;
                const routeWithUserType = to.matched.find(record => record.meta.userType);

                if (routeWithUserType) {
                    if (routeWithUserType.meta.userType !== userType) {
                        next({ name: 'accessDenied' });
                    } else {
                        document.title = `${to.meta.title} - Zyotis Guru`;
                        next();
                    }
                } else {
                    document.title = `${to.meta.title} - Zyotis Guru`;
                    next();
                }
            } catch (error) {
                console.error(error);
                next({ name: 'error' });
            }
        }
    } else {
        document.title = `${to.meta.title} - Zyotis Guru`;
        next();
    }
});

export default router;